import { LinkButton } from '@/components/button/LinkButton';
import { Box, Typography } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

export const AssociationContentSP: React.VFC = () => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 0.5,
          bgcolor: 'primary.main',
          p: 0.5,
          fontWeight: 'bold',
        }}
      >
        <ArrowDropDownIcon fontSize="large" sx={{ color: 'primary.contrastText' }} />
        <Typography variant="h3" fontSize="16px" color="white">
          協会関連コンテンツ
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <LinkButton
          title="パブリックコメント"
          href="https://entry.rabbynet.zennichi.or.jp/PublicCommentMember/MemberPubcomListGroup"
          buttonStyle={{ borderTop: 0 }}
          pcIcon
        />
        <LinkButton
          title="全日不動産ナビ"
          target="_blank"
          href="https://www.zennichi-navi.jp/"
          buttonStyle={{ borderTop: 0 }}
          pcIcon
        />
        <LinkButton
          title="月刊不動産"
          target="_blank"
          href="http://www.zennichi.or.jp/magazine/"
          buttonStyle={{ borderTop: 0 }}
          pcIcon
        />
      </Box>
    </>
  );
};
