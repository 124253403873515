import Image from 'next/image';
import { Box, Button, List, ListItem, Typography } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {
  buyCategoryDataSet,
  CategoryItem,
  rentCategoryDataSet,
} from '@/services/data/propertyCategoryList';
import { BranchItem } from '@/services/data/prefectureLinkList';
import { LinkAccordion } from '@/components/accordion/LinkAccordion';
import { LinkButton } from '@/components/button/LinkButton';
import navtitleRentSP from '@/public/navtitle-rent-sp.png';
import navtitleSaleSP from '@/public/navtitle-sale-sp.png';
import resortIcon from '@/public/sp-resort-icon.png';
import estateIcon from '@/public/sp-estate-icon.png';
import labbyList from '@/public/labby-list.png';

interface Props {
  branchItem?: BranchItem;
}

const emptyItem = (
  <Box
    sx={{
      width: '100%',
      height: '100%',
      borderBottom: '1px solid',
      borderRight: '1px solid',
      borderColor: 'grey.400',
      bgcolor: 'grey.200',
    }}
  >
    <Box
      sx={{
        height: '46px',
        width: '59px',
        position: 'absolute',
        bottom: 0,
        right: '10px',
      }}
    >
      <Image src={labbyList} height="46px" width="59px" alt="" />
    </Box>
  </Box>
);

export const NavLinkContentSP: React.VFC<Props> = ({ branchItem }) => {
  const buildHref = (href: string) => {
    if (branchItem) {
      return `${branchItem.href}${href}${branchItem.prefectureItem?.href}`;
    }
    return href;
  };
  const buildResortHref = () => {
    if (branchItem) {
      return `${branchItem.href}/buy/resort`;
    }
    return '/buy/resort';
  };

  const buyCustomDataSet = [
    ...buyCategoryDataSet.slice(0, 5),
    {} as CategoryItem,
    ...buyCategoryDataSet.slice(5, 6),
  ];
  return (
    <Box>
      <Typography
        variant="h2"
        fontSize="20px"
        color="white"
        sx={{ bgcolor: 'grey.700', p: 1, fontWeight: 'bold' }}
      >
        物件を探す
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5, p: 1 }}>
        <Button
          href="/page/page_sp_disaster"
          variant="outlined"
          size="large"
          fullWidth
          endIcon={<ChevronRightIcon sx={{ color: 'grey.500' }} />}
          sx={{
            justifyContent: 'space-between',
            borderRadius: 0,
            color: 'text.primary',
            fontWeight: 'bold',
            fontSize: '18px',
            borderWidth: 2,
            px: '12px',
            textDecoration: 'none',
          }}
        >
          被災された方向けの物件を探す
        </Button>
        <Box>
          <LinkAccordion summaryTitle="借りる（賃貸）" image={navtitleRentSP}>
            <List
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                borderLeft: '1px solid',
                borderColor: 'grey.400',
                p: 0,
              }}
            >
              {rentCategoryDataSet.map((item, index) => (
                <ListItem
                  key={`rent-category-link-${index}`}
                  sx={{
                    p: 0,
                    width: '50%',
                    '&:first-of-type': {
                      width: '100%',
                    },
                    '&:last-of-type': {
                      width: '100%',
                    },
                    '&:nth-last-of-type(n+1)': {
                      a: {
                        borderTop: 'none',
                        borderLeft: 'none',
                      },
                    },
                  }}
                >
                  <LinkButton title={item.title} href={buildHref(item.href)} image={item.spImage} />
                </ListItem>
              ))}
            </List>
          </LinkAccordion>
          <LinkAccordion summaryTitle="買う" image={navtitleSaleSP}>
            <List
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                borderLeft: '1px solid',
                borderColor: 'grey.400',
                p: 0,
              }}
            >
              {buyCustomDataSet.map((item, index) => {
                if (index === 5) {
                  return (
                    <ListItem
                      key={`buy-category-empty`}
                      sx={{
                        p: 0,
                        width: '50%',
                        '&:last-of-type': {
                          width: '100%',
                        },
                        '&:nth-last-of-type(n+1)': {
                          a: {
                            borderTop: 'none',
                            borderLeft: 'none',
                          },
                        },
                      }}
                    >
                      {emptyItem}
                    </ListItem>
                  );
                } else {
                  return (
                    <ListItem
                      key={`buy-category-link-${index}`}
                      sx={{
                        p: 0,
                        width: '50%',
                        '&:last-of-type': {
                          width: '100%',
                        },
                        '&:nth-last-of-type(n+1)': {
                          a: {
                            borderTop: 'none',
                            borderLeft: 'none',
                          },
                        },
                      }}
                    >
                      <LinkButton
                        title={item.title}
                        href={buildHref(item.href)}
                        image={item.spImage}
                      />
                    </ListItem>
                  );
                }
              })}
            </List>
          </LinkAccordion>
          <LinkButton
            title="リゾート物件を探す"
            href={buildResortHref()}
            image={resortIcon}
            variant="h2"
            buttonStyle={{ mb: 0.125, '&:hover': { bgcolor: 'primary.main' } }}
          />
          <LinkButton
            title="不動産会社を探す"
            href={buildHref('/agency')}
            image={estateIcon}
            variant="h2"
            buttonStyle={{ '&:hover': { bgcolor: 'primary.main' } }}
          />
        </Box>
      </Box>
    </Box>
  );
};
