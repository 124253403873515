import * as React from 'react';
import Image, { StaticImageData } from 'next/image';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';

interface LinkAccordionProps {
  children: React.ReactNode;
  summaryTitle: string;
  image?: string | StaticImageData;
  defaultExpanded?: boolean;
}

export const LinkAccordion: React.VFC<LinkAccordionProps> = ({
  children,
  summaryTitle,
  image,
  defaultExpanded = true,
}) => {
  return (
    <Accordion defaultExpanded={defaultExpanded}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        {image && (
          <Box sx={{ position: 'relative', width: '38px', height: '36px', mr: 1 }}>
            <Image src={image} alt="" />
          </Box>
        )}
        <Typography
          variant="h2"
          sx={{
            color: '#ffffff',
            fontSize: '18px',
            fontWeight: 'bold',
            lineHeight: '36px',
          }}
        >
          {summaryTitle}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};
