import Image from 'next/image';
import { Box, Grid, Link, Typography } from '@mui/material';
import { styled, experimental_sx as sx } from '@mui/material/styles';

import ehIcon01 from '@/public/eh-icon01.png';
import ehIcon02 from '@/public/eh-icon02.png';
import ehIcon03 from '@/public/eh-icon03.png';
import ehIcon04 from '@/public/eh-icon04.png';

const SearchButtonTitle = styled(Typography)(() =>
  sx({
    color: 'primary.main',
    lineHeight: 1.25,
    textDecoration: 'underline',
    fontSize: '14px',
  }),
);

const LinkButton = styled(Link)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  maxWidth: '360px',
  height: '100%',
  columnGap: 8,
  textDecoration: 'none',
}));

const LinkIconWrap = styled(Box)(() => ({
  position: 'relative',
  minWidth: '72px',
  width: '72px',
  height: '72px',
}));

export const EstateHelpContentPC: React.VFC = () => {
  return (
    <Box my={4}>
      <Typography
        variant="h3"
        sx={{
          pb: 0.5,
          borderBottom: '4px solid',
          borderColor: 'primary.light',
        }}
      >
        不動産のお役立ち情報
      </Typography>
      <Grid container justifyContent="space-between" rowGap={1.5} py={2}>
        <Grid item xs={6}>
          <LinkButton target="_blank" href="http://www.zennichi.or.jp/law_faq/">
            <LinkIconWrap>
              <Image src={ehIcon01} alt="" />
            </LinkIconWrap>
            <Box display="flex" flexDirection="column" rowGap={0.5} maxWidth="260px">
              <SearchButtonTitle textAlign="start">法務・税務・賃貸Q&A</SearchButtonTitle>
              <Typography
                variant="body2"
                lineHeight="1.2"
                textAlign="justify"
                color="text.primary"
                fontSize="12px"
              >
                不動産に関する身近な質問から、起こりがちなトラブル事例などを元に、専門家がわかりやすく説明します。
              </Typography>
            </Box>
          </LinkButton>
        </Grid>

        <Grid item xs={6}>
          <LinkButton
            target="_blank"
            href="http://www.zennichi.or.jp/fudousan_kanren/fudousan_kanren_list.php"
          >
            <LinkIconWrap>
              <Image src={ehIcon02} alt="" />
            </LinkIconWrap>
            <Box display="flex" flexDirection="column" rowGap={0.5} maxWidth="260px">
              <SearchButtonTitle textAlign="start">行政機関からの通達・告知</SearchButtonTitle>
              <Typography
                variant="body2"
                lineHeight="1.2"
                textAlign="justify"
                color="text.primary"
                fontSize="12px"
              >
                行政機関からの告知や通知をお知らせ。また各種セミナーやシンポジウム、イベント等のご紹介もしております。
              </Typography>
            </Box>
          </LinkButton>
        </Grid>

        <Grid item xs={6}>
          <LinkButton target="_blank" href="http://www.zennichi.or.jp/low/low_list.php">
            <LinkIconWrap>
              <Image src={ehIcon03} alt="" />
            </LinkIconWrap>
            <Box display="flex" flexDirection="column" rowGap={0.5} maxWidth="260px">
              <SearchButtonTitle textAlign="start">法令改正情報</SearchButtonTitle>
              <Typography
                variant="body2"
                lineHeight="1.2"
                textAlign="justify"
                color="text.primary"
                fontSize="12px"
              >
                既に施行された法令だけでなく、これから改正される法令に関しても先取りしてお伝えします。改正に伴うポイントや注意点等、業務に役立つ情報を掲載しています。
              </Typography>
            </Box>
          </LinkButton>
        </Grid>

        <Grid item xs={6}>
          <LinkButton target="_blank" href="http://www.zennichi.or.jp/kaigyou_manual/index.php">
            <LinkIconWrap>
              <Image src={ehIcon04} alt="" />
            </LinkIconWrap>
            <Box display="flex" flexDirection="column" rowGap={0.5} maxWidth="260px">
              <SearchButtonTitle textAlign="start">不動産開業の手引き</SearchButtonTitle>
              <Typography
                variant="body2"
                lineHeight="1.2"
                textAlign="justify"
                color="text.primary"
                fontSize="12px"
              >
                不動産開業までの流れをわかりやすくご紹介いたします。
              </Typography>
            </Box>
          </LinkButton>
        </Grid>
      </Grid>
    </Box>
  );
};
