import { LinkButton } from '@/components/button/LinkButton';
import { Box, Typography } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

export const EstateHelpContentSP: React.VFC = () => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 0.5,
          bgcolor: 'primary.main',
          p: 0.5,
          fontWeight: 'bold',
        }}
      >
        <ArrowDropDownIcon fontSize="large" sx={{ color: 'primary.contrastText' }} />
        <Typography variant="h3" fontSize="16px" color="white">
          不動産のお役立ち情報
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <LinkButton
          title="法務・税務・賃貸Q&A"
          target="_blank"
          href="http://www.zennichi.or.jp/law_faq/"
          buttonStyle={{ borderTop: 0 }}
        />
        <LinkButton
          title="行政機関からの通達・告知"
          target="_blank"
          href="http://www.zennichi.or.jp/fudousan_kanren/fudousan_kanren_list.php"
          buttonStyle={{ borderTop: 0 }}
        />
        <LinkButton
          title="法令改正情報"
          target="_blank"
          href="http://www.zennichi.or.jp/low/low_list.php"
          buttonStyle={{ borderTop: 0 }}
        />
        <LinkButton
          title="不動産開業の手引き"
          target="_blank"
          href="http://www.zennichi.or.jp/kaigyou_manual/index.php"
          buttonStyle={{ borderTop: 0 }}
          pcIcon
        />
      </Box>
    </>
  );
};
