import Image from 'next/image';
import { Box, Link, Typography } from '@mui/material';
import { rentRecommendLinkList, saleRecommendLinkList } from '@/services/data/propertyCategoryList';
import { BranchItem } from '@/services/data/prefectureLinkList';
import { LinkButton } from '@/components/button/LinkButton';
import navtitleRentSP from '@/public/navtitle-rent-sp.png';
import navtitleSaleSP from '@/public/navtitle-sale-sp.png';
import labbyBanner from '@/public/labby-banner.png';

interface Props {
  branchItem?: BranchItem;
}

export const RecommendContentSP: React.VFC<Props> = ({ branchItem }) => {
  const buildHref = (href: string) => {
    if (branchItem) {
      return `${branchItem.href}${href}${branchItem.prefectureItem?.href}`;
    }
    return href;
  };
  return (
    <Box>
      <Typography
        variant="h2"
        fontSize="20px"
        color="white"
        sx={{ bgcolor: 'grey.700', p: 1, fontWeight: 'bold' }}
      >
        おすすめ特集
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', p: 1 }}>
        <Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              bgcolor: 'primary.main',
              p: '10px 12px',
            }}
          >
            <Box sx={{ position: 'relative', width: '38px', height: '36px' }}>
              <Image src={navtitleRentSP} alt="" />
            </Box>
            <Typography variant="h3" color="primary.contrastText" sx={{ fontSize: '16px' }}>
              借りる（賃貸）
            </Typography>
          </Box>
          {rentRecommendLinkList.map((item, index) => (
            <LinkButton
              title={item.title}
              href={buildHref(item.href)}
              image={item.spImageData}
              buttonStyle={{ borderTop: 0 }}
              key={`rent-recommend-${index}`}
            />
          ))}
        </Box>

        <Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              bgcolor: 'primary.main',
              p: '10px 12px',
            }}
          >
            <Box sx={{ position: 'relative', width: '38px', height: '36px' }}>
              <Image src={navtitleSaleSP} alt="" />
            </Box>
            <Typography variant="h3" color="primary.contrastText" sx={{ fontSize: '16px' }}>
              買う
            </Typography>
          </Box>
          {saleRecommendLinkList.map((item, index) => (
            <LinkButton
              title={item.title}
              href={buildHref(item.href)}
              image={item.spImageData}
              buttonStyle={{ borderTop: 0 }}
              key={`sale-recommend-${index}`}
            />
          ))}
        </Box>

        <Link
          target="_blank"
          href="http://www.zennichi.or.jp/about/rabby/"
          sx={{
            position: 'relative',
            width: '300px',
            height: '147.2px',
            m: '21px auto',
            overflowX: 'hidden',
            maxWidth: '100%',
          }}
        >
          <Image src={labbyBanner} alt="ラビーちゃんの紹介" />
        </Link>
      </Box>
    </Box>
  );
};
