import { Box, Typography } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { LinkButton } from '@/components/button/LinkButton';

export const EstateBasicContentSP: React.VFC = () => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 0.5,
          bgcolor: 'primary.main',
          p: 0.5,
          fontWeight: 'bold',
        }}
      >
        <ArrowDropDownIcon fontSize="large" sx={{ color: 'primary.contrastText' }} />
        <Typography variant="h3" fontSize="16px" color="white">
          不動産の基礎知識
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <LinkButton
          title="「借りる」ときに知っておきたいこと"
          target="_blank"
          href="https://www.fudousan.or.jp/kiso/rent/index.html"
          buttonStyle={{ borderTop: 0 }}
          pcIcon
        />
        <LinkButton
          title="「買う」ときに知っておきたいこと"
          target="_blank"
          href="https://www.fudousan.or.jp/kiso/buy/index.html"
          buttonStyle={{ borderTop: 0 }}
          pcIcon
        />
        <LinkButton
          title="「貸す」ときに知っておきたいこと"
          target="_blank"
          href="https://www.fudousan.or.jp/kiso/lease/index.html"
          buttonStyle={{ borderTop: 0 }}
          pcIcon
        />
        <LinkButton
          title="「売る」ときに知っておきたいこと"
          target="_blank"
          href="https://www.fudousan.or.jp/kiso/sale/index.html"
          buttonStyle={{ borderTop: 0 }}
          pcIcon
        />
      </Box>
    </>
  );
};
