import Image from 'next/image';
import { Box, Grid, Link, Typography } from '@mui/material';
import { styled, experimental_sx as sx } from '@mui/material/styles';

import lifeHelpIcon01 from '@/public/lifehelp-icon01.png';
import lifeHelpIcon02 from '@/public/lifehelp-icon02.png';
import lifeHelpIcon03 from '@/public/lifehelp-icon03.png';
import lifeHelpIcon04 from '@/public/lifehelp-icon04.png';
import lifeHelpIcon05 from '@/public/lifehelp-icon05.png';
import lifeHelpIcon06 from '@/public/lifehelp-icon06.png';
import { BranchItem } from '@/services/data/prefectureLinkList';

const SearchButtonTitle = styled(Typography)(() =>
  sx({
    color: 'primary.main',
    lineHeight: 1.25,
    textDecoration: 'underline',
    fontSize: '14px',
  }),
);

const LinkButton = styled(Link)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  maxWidth: '360px',
  height: '100%',
  columnGap: 8,
  textDecoration: 'none',
}));

const LinkIconWrap = styled(Box)(() => ({
  position: 'relative',
  minWidth: '72px',
  width: '72px',
  height: '72px',
}));

interface Props {
  branchItem?: BranchItem;
}

export const LifeHelpContentPC: React.VFC<Props> = ({ branchItem }) => {
  const buildHref = (href: string) => {
    if (branchItem) {
      return `${branchItem.href}${href}`;
    }
    return href;
  };
  return (
    <Box my={4}>
      <Typography
        variant="h3"
        sx={{
          pb: 0.5,
          borderBottom: '4px solid',
          borderColor: 'primary.light',
        }}
      >
        住まいのお役立ち情報
      </Typography>
      <Grid container justifyContent="space-between" rowGap={1.5} py={2}>
        <Grid item xs={6}>
          <LinkButton target="_blank" href={buildHref('/sumaisagashi/heyasagashi')}>
            <LinkIconWrap>
              <Image src={lifeHelpIcon01} alt="" />
            </LinkIconWrap>
            <Box display="flex" flexDirection="column" rowGap={0.5} maxWidth="260px">
              <SearchButtonTitle textAlign="start">部屋探しマニュアル</SearchButtonTitle>
              <Typography
                variant="body2"
                lineHeight="1.2"
                textAlign="justify"
                color="text.primary"
                fontSize="12px"
              >
                「引っ越したい」「部屋を住み替えたい」そんなとき、不動産会社を訪問する前に、どんな順番で何を決める必要があるのかをご紹介します。
              </Typography>
            </Box>
          </LinkButton>
        </Grid>

        <Grid item xs={6}>
          <LinkButton target="_blank" href={buildHref('/sumaisagashi/hikkoshi')}>
            <LinkIconWrap>
              <Image src={lifeHelpIcon02} layout="fill" alt="" />
            </LinkIconWrap>
            <Box display="flex" flexDirection="column" rowGap={0.5} maxWidth="260px">
              <SearchButtonTitle textAlign="start">引越しマニュアル</SearchButtonTitle>
              <Typography
                variant="body2"
                lineHeight="1.2"
                textAlign="justify"
                color="text.primary"
                fontSize="12px"
              >
                引越し会社に依頼の電話をする前に、引越しの段取りをシミュレーションできます。便利なチェックリストもご利用下さい。
              </Typography>
            </Box>
          </LinkButton>
        </Grid>

        <Grid item xs={6}>
          <LinkButton target="_blank" href="https://www.zennichi.net/guide/index.html">
            <LinkIconWrap>
              <Image src={lifeHelpIcon03} alt="" />
            </LinkIconWrap>
            <Box display="flex" flexDirection="column" rowGap={0.5} maxWidth="260px">
              <SearchButtonTitle textAlign="start">入居ガイド</SearchButtonTitle>
              <Typography
                variant="body2"
                lineHeight="1.2"
                textAlign="justify"
                color="text.primary"
                fontSize="12px"
              >
                移転についての公共機関の転入出・電気・ガス・水道の開始停止・地域特有の防災情報・学区域情報・ゴミ処理方法・収集日を各区市町村に分け掲載しています。
              </Typography>
            </Box>
          </LinkButton>
        </Grid>

        <Grid item xs={6}>
          <LinkButton target="_blank" href="https://www.re-words.net/">
            <LinkIconWrap>
              <Image src={lifeHelpIcon04} alt="" />
            </LinkIconWrap>
            <Box display="flex" flexDirection="column" rowGap={0.5} maxWidth="260px">
              <SearchButtonTitle textAlign="start">不動産用語集</SearchButtonTitle>
              <Typography
                variant="body2"
                lineHeight="1.2"
                textAlign="justify"
                color="text.primary"
                fontSize="12px"
              >
                不動産、住宅、民法、税制、不動産証券化、法規制といった不動産に関連する用語を多数収録、解説した用語集です。
              </Typography>
            </Box>
          </LinkButton>
        </Grid>

        <Grid item xs={6}>
          <LinkButton target="_blank" href="http://www.contract.reins.or.jp/">
            <LinkIconWrap>
              <Image src={lifeHelpIcon05} alt="" />
            </LinkIconWrap>
            <Box display="flex" flexDirection="column" rowGap={0.5} maxWidth="260px">
              <SearchButtonTitle textAlign="start">REINS Market Information</SearchButtonTitle>
              <Typography
                variant="body2"
                lineHeight="1.2"
                textAlign="justify"
                color="text.primary"
                fontSize="12px"
              >
                実際に売買が行われた物件の価格（成約価格）等の取引情報を検索することができます。
              </Typography>
            </Box>
          </LinkButton>
        </Grid>

        <Grid item xs={6}>
          <LinkButton target="_blank" href="https://www.mirainet.org/">
            <LinkIconWrap>
              <Image src={lifeHelpIcon06} alt="" />
            </LinkIconWrap>
            <Box display="flex" flexDirection="column" rowGap={0.5} maxWidth="260px">
              <SearchButtonTitle textAlign="start">マンションみらいネット</SearchButtonTitle>
              <Typography
                variant="body2"
                lineHeight="1.2"
                textAlign="justify"
                color="text.primary"
                fontSize="12px"
              >
                マンションの管理状況を積極的に公開しているマンションを紹介するサイトへのリンクです。
              </Typography>
            </Box>
          </LinkButton>
        </Grid>
      </Grid>
    </Box>
  );
};
