import { LinkButton } from '@/components/button/LinkButton';
import { Box, Typography } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

export const TrendContentSP: React.VFC = () => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 0.5,
          bgcolor: 'primary.main',
          p: 0.5,
          fontWeight: 'bold',
        }}
      >
        <ArrowDropDownIcon fontSize="large" sx={{ color: 'primary.contrastText' }} />
        <Typography variant="h3" fontSize="16px" color="white">
          家賃相場・取引動向
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <LinkButton
          title="土地"
          target="_blank"
          href="https://www.fudousan.or.jp/trend/tochi.html"
          buttonStyle={{ borderTop: 0 }}
          pcIcon
        />
        <LinkButton
          title="新築マンション"
          target="_blank"
          href="https://www.fudousan.or.jp/trend/sinm.html"
          buttonStyle={{ borderTop: 0 }}
          pcIcon
        />
        <LinkButton
          title="中古マンション"
          target="_blank"
          href="https://www.fudousan.or.jp/trend/cyum.html"
          buttonStyle={{ borderTop: 0 }}
          pcIcon
        />
        <LinkButton
          title="一戸建て（新築・中古）"
          target="_blank"
          href="https://www.fudousan.or.jp/trend/ikko.html"
          buttonStyle={{ borderTop: 0 }}
          pcIcon
        />
        <LinkButton
          title="賃貸"
          target="_blank"
          href="https://www.fudousan.or.jp/trend/chintai.html"
          buttonStyle={{ borderTop: 0 }}
          pcIcon
        />
      </Box>
    </>
  );
};
