import { Box, Typography, List, ListItem, ListItemIcon, ListItemText, Link } from '@mui/material';
import { styled } from '@mui/material/styles';
import Image from 'next/image';

import ArrowRightIcon from '@mui/icons-material/ArrowRight';

const SubTitle = styled(Typography)(({ theme }) => ({
  borderLeft: `5px solid ${theme.palette.primary.light}`,
  paddingLeft: '10px',
  fontSize: '16px',
  marginLeft: '8px',
  marginBottom: '10px',
}));

export const AssociationContentPC: React.VFC = () => {
  return (
    <Box sx={{ bgcolor: 'grey.100', py: 2, px: 3 }}>
      <SubTitle>全日本不動産協会・不動産保証協会情報</SubTitle>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box p={2} bgcolor="white" width="226px" m={1}>
          <Typography variant="body2">全日本不動産協会について</Typography>
          <List sx={{ py: 0, ml: -1 }}>
            <ListItem sx={{ alignItems: 'center', p: 0 }}>
              <ListItemIcon sx={{ height: '24px', minWidth: '24px' }}>
                <ArrowRightIcon sx={{ color: 'accent.main' }} />
              </ListItemIcon>
              <ListItemText sx={{ m: 0 }}>
                <Link target="_blank" href="http://www.zennichi.or.jp/zennichi/zennichi_top.php">
                  全日本不動産協会とは
                </Link>
              </ListItemText>
            </ListItem>

            <ListItem sx={{ alignItems: 'center', p: 0 }}>
              <ListItemIcon sx={{ height: '24px', minWidth: '24px' }}>
                <ArrowRightIcon sx={{ color: 'accent.main' }} />
              </ListItemIcon>
              <ListItemText sx={{ m: 0 }}>
                <Link target="_blank" href="http://www.zennichi.or.jp/zennichi/zennichi_enkaku.php">
                  沿革
                </Link>
              </ListItemText>
            </ListItem>

            <ListItem sx={{ alignItems: 'center', p: 0 }}>
              <ListItemIcon sx={{ height: '24px', minWidth: '24px' }}>
                <ArrowRightIcon sx={{ color: 'accent.main' }} />
              </ListItemIcon>
              <ListItemText sx={{ m: 0 }}>
                <Link target="_blank" href="http://www.zennichi.or.jp/zennichi/zennichi_ir.php">
                  ディスクロージャー
                </Link>
              </ListItemText>
            </ListItem>
          </List>
        </Box>

        <Box p={2} bgcolor="white" width="226px" m={1}>
          <Typography variant="body2">不動産保証協会について</Typography>
          <List sx={{ py: 0, ml: -1 }}>
            <ListItem sx={{ alignItems: 'center', p: 0 }}>
              <ListItemIcon sx={{ height: '24px', minWidth: '24px' }}>
                <ArrowRightIcon sx={{ color: 'accent.main' }} />
              </ListItemIcon>
              <ListItemText sx={{ m: 0 }}>
                <Link target="_blank" href="https://www.fudousanhosho.or.jp/about/">
                  全日本不動産保証協会とは
                </Link>
              </ListItemText>
            </ListItem>

            <ListItem sx={{ alignItems: 'center', p: 0 }}>
              <ListItemIcon sx={{ height: '24px', minWidth: '24px' }}>
                <ArrowRightIcon sx={{ color: 'accent.main' }} />
              </ListItemIcon>
              <ListItemText sx={{ m: 0 }}>
                <Link target="_blank" href="https://www.fudousanhosho.or.jp/about/history/">
                  沿革
                </Link>
              </ListItemText>
            </ListItem>

            <ListItem sx={{ alignItems: 'center', p: 0 }}>
              <ListItemIcon sx={{ height: '24px', minWidth: '24px' }}>
                <ArrowRightIcon sx={{ color: 'accent.main' }} />
              </ListItemIcon>
              <ListItemText sx={{ m: 0 }}>
                <Link target="_blank" href="https://www.fudousanhosho.or.jp/company/ir_joho.php">
                  ディスクロージャー
                </Link>
              </ListItemText>
            </ListItem>
          </List>
        </Box>

        <Box p={2} bgcolor="white" width="226px" m={1}>
          <Typography variant="body2">所在地のご案内</Typography>
          <List sx={{ py: 0, ml: -1 }}>
            <ListItem sx={{ alignItems: 'center', p: 0 }}>
              <ListItemIcon sx={{ height: '24px', minWidth: '24px' }}>
                <ArrowRightIcon sx={{ color: 'accent.main' }} />
              </ListItemIcon>
              <ListItemText sx={{ m: 0 }}>
                <Link target="_blank" href="http://www.zennichi.or.jp/about/address/">
                  総本部
                </Link>
              </ListItemText>
            </ListItem>

            <ListItem sx={{ alignItems: 'center', p: 0 }}>
              <ListItemIcon sx={{ height: '24px', minWidth: '24px' }}>
                <ArrowRightIcon sx={{ color: 'accent.main' }} />
              </ListItemIcon>
              <ListItemText sx={{ m: 0 }}>
                <Link target="_blank" href="http://www.zennichi.or.jp/chihou/chihou_list.php">
                  地方本部
                </Link>
              </ListItemText>
            </ListItem>

            <ListItem sx={{ alignItems: 'center', p: 0 }}>
              <ListItemIcon sx={{ height: '24px', minWidth: '24px' }}>
                <ArrowRightIcon sx={{ color: 'accent.main' }} />
              </ListItemIcon>
              <ListItemText sx={{ m: 0 }}>
                <Link
                  target="_blank"
                  href="http://www.zennichi.or.jp/chihou/chihou_distribution.php"
                >
                  流通センター
                </Link>
              </ListItemText>
            </ListItem>
          </List>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={{ p: '10px 18px 5px 18px', m: 1, bgcolor: '#ffffff', width: '354px' }}>
          <Link
            target="_blank"
            href="https://www.zennichi.net/"
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            <Image
              src="/info-banner01.png"
              width="290px"
              height="60px"
              alt="全日本不動産関東流通センター"
            />
          </Link>
        </Box>
        <Box sx={{ p: '10px 18px 10px 18px', m: 1, bgcolor: '#ffffff', width: '354px' }}>
          <Link
            target="_blank"
            href="http://www.kinki.zennichi.or.jp/"
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            <Image
              src="/info-banner02.png"
              width="290px"
              height="60px"
              alt="全日本不動産近畿流通センター"
            />
          </Link>
        </Box>
      </Box>
    </Box>
  );
};
