import { LinkButton } from '@/components/button/LinkButton';
import { Box, Typography } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

export const AreaInfoContentSP: React.VFC = () => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 0.5,
          bgcolor: 'primary.main',
          p: 0.5,
          fontWeight: 'bold',
        }}
      >
        <ArrowDropDownIcon fontSize="large" sx={{ color: 'primary.contrastText' }} />
        <Typography variant="h3" fontSize="16px" color="white">
          住みたいエリアの環境情報
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <LinkButton
          title="子育て支援サービスなど"
          target="_blank"
          href="https://www.fudousan.or.jp/jyukankyo/child/hoiku.html"
          buttonStyle={{ borderTop: 0 }}
          pcIcon
        />
        <LinkButton
          title="防災関連情報"
          target="_blank"
          href="https://www.fudousan.or.jp/jyukankyo/bosai/map.html"
          buttonStyle={{ borderTop: 0 }}
          pcIcon
        />
        <LinkButton
          title="防犯関連情報"
          target="_blank"
          href="https://www.fudousan.or.jp/jyukankyo/bohan/index.html"
          buttonStyle={{ borderTop: 0 }}
          pcIcon
        />
        <LinkButton
          title="各自治体の情報"
          target="_blank"
          href="https://www.fudousan.or.jp/government/"
          buttonStyle={{ borderTop: 0 }}
          pcIcon
        />
      </Box>
    </>
  );
};
