import Image from 'next/image';
import { Box, Button, Grid, Typography } from '@mui/material';
import { styled, experimental_sx as sx } from '@mui/material/styles';
import { rentRecommendLinkList, saleRecommendLinkList } from '@/services/data/propertyCategoryList';
import { BranchItem } from '@/services/data/prefectureLinkList';

const SearchGrid = styled(Grid)(() =>
  sx({
    rowGap: '12px',
    padding: '16px 0',
    '&::before': {
      content: '""',
      display: 'block',
      width: '150px',
      order: 1,
    },
    '&::after': {
      content: '""',
      display: 'block',
      width: '150px',
    },
  }),
);

const SearchButton = styled(Button)(() =>
  sx({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '146px',
    height: '100%',
    rowGap: 1,
  }),
);

const SearchButtonTitle = styled(Typography)(() =>
  sx({
    color: 'primary.main',
    lineHeight: 1.25,
    textDecoration: 'underline',
    textAlign: 'center',
  }),
);

const SearchIconWrap = styled(Box)(() =>
  sx({
    position: 'relative',
    width: '119px',
    height: '111px',
  }),
);

interface Props {
  branchItem?: BranchItem;
}

export const RecommendContentPC: React.VFC<Props> = ({ branchItem }) => {
  const buildHref = (href: string) => {
    if (branchItem) {
      return `${branchItem.href}${href}${branchItem.prefectureItem?.href}`;
    }
    return href;
  };
  return (
    <Box my={4}>
      <Typography
        variant="h2"
        sx={{
          pb: 0.5,
          fontSize: '30px',
          fontWeight: 'bold',
          borderBottom: '5px solid',
          borderColor: 'primary.light',
          position: 'relative',
          '&::after': {
            position: 'absolute',
            bottom: '-12px',
            left: '185px',
            display: 'inline-block',
            content: `url(/labbytitle-left.png)`,
          },
        }}
      >
        おすすめ特集
      </Typography>
      <SearchGrid container>
        {rentRecommendLinkList.map((item, index) => (
          <Grid item key={`rent-recommend-${index}`}>
            <SearchButton href={buildHref(item.href)}>
              <SearchIconWrap>
                <Image src={item.imageData} alt="" />
              </SearchIconWrap>
              <SearchButtonTitle>{item.title}</SearchButtonTitle>
              <Typography variant="body2" color="text.primary" textAlign="justify">
                {item.description}
              </Typography>
            </SearchButton>
          </Grid>
        ))}
        {saleRecommendLinkList.map((item, index) => (
          <Grid item key={`sale-recommend-${index}`}>
            <SearchButton href={buildHref(item.href)}>
              <SearchIconWrap>
                <Image src={item.imageData} alt="" />
              </SearchIconWrap>
              <SearchButtonTitle>{item.title}</SearchButtonTitle>
              <Typography variant="body2" color="text.primary" textAlign="justify">
                {item.description}
              </Typography>
            </SearchButton>
          </Grid>
        ))}
      </SearchGrid>
    </Box>
  );
};
