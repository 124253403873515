import Image from 'next/image';
import { Box, Grid, Button, Typography, ButtonProps, LinkProps } from '@mui/material';
import { styled, experimental_sx as sx } from '@mui/material/styles';

import ebIcon01 from '@/public/eb-icon01.png';
import ebIcon02 from '@/public/eb-icon02.png';
import ebIcon03 from '@/public/eb-icon03.png';
import ebIcon04 from '@/public/eb-icon04.png';

const SearchButtonTitle = styled(Typography)(() =>
  sx({
    color: 'primary.main',
    lineHeight: 1.25,
    textDecoration: 'underline',
    fontSize: '14px',
  }),
);

const EbLinkButton = styled((props: ButtonProps & LinkProps) => <Button {...props} />)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  maxWidth: '345px',
  height: '100%',
  columnGap: 4,
}));

const EbLinkIconWrap = styled(Box)(() => ({
  position: 'relative',
  minWidth: '59px',
  width: '59px',
  height: '59px',
}));

export const EstateBasicContentPC: React.VFC = () => {
  return (
    <Box my={4}>
      <Typography
        variant="h3"
        sx={{
          pb: 0.5,
          borderBottom: '4px solid',
          borderColor: 'primary.light',
        }}
      >
        不動産の基礎知識
      </Typography>
      <Grid
        container
        justifyContent="space-between"
        p={2}
        border="1px solid"
        borderColor="grey.300"
        rowGap={1.5}
      >
        <Grid item xs={6}>
          <EbLinkButton target="_blank" href="https://www.fudousan.or.jp/kiso/rent/index.html">
            <EbLinkIconWrap>
              <Image src={ebIcon01} alt="" />
            </EbLinkIconWrap>
            <Box display="flex" flexDirection="column" rowGap={0.5} maxWidth="260px">
              <SearchButtonTitle textAlign="start">
                「借りる」ときに知っておきたいこと
              </SearchButtonTitle>
              <Typography
                variant="body2"
                lineHeight="1.2"
                textAlign="justify"
                color="text.primary"
                fontSize="12px"
              >
                住まい探しを始めてから、入居物件の賃貸借契約を結び、実際に入居をした後、退去に至るまで
              </Typography>
            </Box>
          </EbLinkButton>
        </Grid>

        <Grid item xs={6}>
          <EbLinkButton target="_blank" href="https://www.fudousan.or.jp/kiso/buy/index.html">
            <EbLinkIconWrap>
              <Image src={ebIcon02} alt="" />
            </EbLinkIconWrap>
            <Box display="flex" flexDirection="column" rowGap={0.5} maxWidth="260px">
              <SearchButtonTitle textAlign="start">
                「買う」ときに知っておきたいこと
              </SearchButtonTitle>
              <Typography
                variant="body2"
                lineHeight="1.2"
                textAlign="justify"
                color="text.primary"
                fontSize="12px"
              >
                住まい探しを始めてから、購入物件の売買契約を結び、実際に入居するまでや入居後のトラブルに至るまで
              </Typography>
            </Box>
          </EbLinkButton>
        </Grid>

        <Grid item xs={6}>
          <EbLinkButton target="_blank" href="https://www.fudousan.or.jp/kiso/lease/index.html">
            <EbLinkIconWrap>
              <Image src={ebIcon03} alt="" />
            </EbLinkIconWrap>
            <Box display="flex" flexDirection="column" rowGap={0.5} maxWidth="260px">
              <SearchButtonTitle textAlign="start">
                「貸す」ときに知っておきたいこと
              </SearchButtonTitle>
              <Typography
                variant="body2"
                lineHeight="1.2"
                textAlign="justify"
                color="text.primary"
                fontSize="12px"
              >
                自宅の賃貸を検討し始めてから、賃貸物件の賃貸借契約を結び、実際に入居者を受け入れてからの管理やトラブル対応に至るまで
              </Typography>
            </Box>
          </EbLinkButton>
        </Grid>

        <Grid item xs={6}>
          <EbLinkButton target="_blank" href="https://www.fudousan.or.jp/kiso/sale/index.html">
            <EbLinkIconWrap>
              <Image src={ebIcon04} alt="" />
            </EbLinkIconWrap>
            <Box display="flex" flexDirection="column" rowGap={0.5} maxWidth="260px">
              <SearchButtonTitle textAlign="start">
                「売る」ときに知っておきたいこと
              </SearchButtonTitle>
              <Typography
                variant="body2"
                lineHeight="1.2"
                textAlign="justify"
                color="text.primary"
                fontSize="12px"
              >
                自宅の売却を検討し始めてから、売却物件の売買契約を結び、物件を引き渡すまで
              </Typography>
            </Box>
          </EbLinkButton>
        </Grid>
      </Grid>
    </Box>
  );
};
