import * as React from 'react';
import Image from 'next/image';
import { Box, Link, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { SideLinkListBox } from '../../components/box/SideLinkListBox';
import sideBanner01 from '../../public/side-banner01.png';
import sideBanner02 from '../../public/side-banner02.png';
import sideBanner03 from '../../public/side-banner03.png';
import sideBanner04 from '../../public/side-banner04.png';
import sideBanner06 from '../../public/side-banner06.png';
import sideBanner07 from '../../public/side-banner07.png';
import qrCode from '../../public/qr-code.png';

const SideBox = styled('aside')(() => ({
  width: '220px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  rowGap: '16px',
  marginLeft: '8px',
  marginRight: '8px',
}));

const ImageWrapBox = styled(Link)(() => ({
  position: 'relative',
}));

const sideLinkIcon = {
  icon: true,
  iconStyle: { bgcolor: 'accent.main', borderRadius: '50%', color: 'white', fontSize: '14px' },
};

const sideLinkItems1 = [
  {
    title: '子育て支援サービス',
    href: 'https://www.fudousan.or.jp/jyukankyo/child/hoiku.html',
    ...sideLinkIcon,
  },
  {
    title: '防災関連情報',
    href: 'https://www.fudousan.or.jp/jyukankyo/bosai/map.html',
    ...sideLinkIcon,
  },
  {
    title: '防犯関連情報',
    href: 'https://www.fudousan.or.jp/jyukankyo/bohan/index.html',
    ...sideLinkIcon,
  },
  {
    title: '各自治体の情報',
    href: 'https://www.fudousan.or.jp/government/',
    ...sideLinkIcon,
  },
];

const sideLinkItems2 = [
  {
    title: '土地',
    href: 'https://www.fudousan.or.jp/trend/tochi.html',
    ...sideLinkIcon,
  },
  {
    title: '新築マンション',
    href: 'https://www.fudousan.or.jp/trend/sinm.html',
    ...sideLinkIcon,
  },
  {
    title: '中古マンション',
    href: 'https://www.fudousan.or.jp/trend/cyum.html',
    ...sideLinkIcon,
  },
  {
    title: '一戸建て（新築・中古）',
    href: 'https://www.fudousan.or.jp/trend/ikko.html',
    ...sideLinkIcon,
  },
  {
    title: '賃貸',
    href: 'https://www.fudousan.or.jp/trend/chintai.html',
    ...sideLinkIcon,
  },
];

export function Sidebar() {
  return (
    <SideBox sx={{ display: { xs: 'none', sm: 'flex' } }}>
      <ImageWrapBox width="220px" height="100px" href="/page/page_pc_disaster" target="_blank">
        <Image src={sideBanner01} alt="被災された方向け物件の検索はこちら" />
      </ImageWrapBox>
      <SideLinkListBox
        links={sideLinkItems1}
        listTitle="住みたいエリアの環境情報"
        listItemOption={{ borderBottom: '1px dashed' }}
      />
      <SideLinkListBox links={sideLinkItems2} listTitle="家賃相場・取引動向" />
      <ImageWrapBox
        width="220px"
        height="130px"
        href="http://www.zennichi.or.jp/about/rabby/"
        target="_blank"
      >
        <Image src={sideBanner02} alt="ラビーちゃんの紹介" />
      </ImageWrapBox>
      <Box sx={{ borderTop: '4px solid', borderColor: 'primary.light' }}>
        <Typography
          variant="h2"
          sx={{
            fontSize: '16px',
            fontWeight: 'bold',
            height: '56px',
            lineHeight: '56px',
            textIndent: '10px',
            border: '0.5px solid',
            borderColor: 'grey.400',
          }}
        >
          スマートフォン・モバイル
        </Typography>
        <Box
          display="flex"
          sx={{
            border: '0.5px solid',
            borderColor: 'grey.400',
            borderTop: 'none',
          }}
        >
          <ImageWrapBox width="87px" height="87px" m={1}>
            {/** TODO: QRコードをページに合わせて生成 */}
            <Image src={qrCode} alt="QRコード" />
          </ImageWrapBox>
          <Typography variant="caption" width="105px" py={1.5} px={0.5}>
            スマホ・モバイルからのアクセスはこちらから
          </Typography>
        </Box>
      </Box>
      <ImageWrapBox
        width="220px"
        height="81px"
        href="https://entry.rabbynet.zennichi.or.jp/PublicCommentMember/MemberPubcomListGroup"
        target="_blank"
      >
        <Image src={sideBanner03} alt="パブリックコメント" />
      </ImageWrapBox>
      <ImageWrapBox
        width="220px"
        height="200px"
        href="https://www.zennichi-navi.jp/"
        target="_blank"
      >
        <Image src={sideBanner04} alt="全日不動産ナビ" />
      </ImageWrapBox>
      <ImageWrapBox
        width="220px"
        height="95px"
        href="http://www.zennichi.or.jp/magazine/"
        target="_blank"
      >
        <Image src={sideBanner06} alt="月間不動産" />
      </ImageWrapBox>
      <Box sx={{ borderTop: '4px solid', borderColor: 'primary.light' }}>
        <Typography
          variant="h2"
          sx={{
            fontSize: '16px',
            fontWeight: 'bold',
            height: '56px',
            lineHeight: '56px',
            textIndent: '10px',
            border: '0.5px solid',
            borderColor: 'grey.400',
          }}
        >
          会員の不動産会社の方へ
        </Typography>
        <Box
          display="flex"
          sx={{
            border: '0.5px solid',
            borderColor: 'grey.400',
            borderTop: 'none',
            p: 1,
            justifyContent: 'center',
          }}
        >
          <ImageWrapBox
            width="190px"
            height="55px"
            href="https://portal.rabbynet.zennichi.or.jp/"
            target="_blank"
          >
            <Image src={sideBanner07} alt="ラビーネット全日会員支援ポータルサイト" />
          </ImageWrapBox>
        </Box>
      </Box>
    </SideBox>
  );
}
