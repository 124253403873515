import { Box, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { RealEstateInformation } from '@/lib/swagger';
import { format } from 'date-fns';
import { NextLink } from '@/components/link/NextLink';

export const NotificationSP: React.VFC<{ informations: RealEstateInformation[] }> = (props) => {
  const informationListItems = props.informations.map((info, index) => {
    const date = format(new Date(info.date), 'yyyy/MM/dd');
    return (
      <ListItem sx={{ alignItems: 'center', p: 0 }} key={index}>
        <ListItemIcon sx={{ height: '24px', minWidth: '24px' }}>
          <ArrowRightIcon sx={{ color: 'accent.main' }} />
        </ListItemIcon>
        <ListItemText
          secondary={
            <Typography variant="body1" fontSize={12}>
              {date + ' '}
              <NextLink href={info.url || '#'} target="_blank">
                {info.title}
              </NextLink>
            </Typography>
          }
          sx={{ m: 0 }}
        />
      </ListItem>
    );
  });

  return (
    <Box sx={{ mb: 2, py: 1.5, px: 0.5, bgcolor: 'grey.200' }}>
      <Typography
        variant="body2"
        fontSize={12}
        sx={{
          p: 0.5,
        }}
      >
        【お知らせ】
      </Typography>
      <List sx={{ p: 0 }}>{informationListItems}</List>
    </Box>
  );
};
