import * as React from 'react';
import { List, SxProps, Typography, Box } from '@mui/material';
import { LinkListItem, LinkListRow } from '../list/LinkListRow';

interface Props {
  listTitle: string;
  links: LinkListItem[];
  listItemOption: SxProps;
}

SideLinkListBox.defaultProps = {
  listItemOption: {},
};

const listStyle = {
  height: '35px',
  lineHeight: '35px',
  borderBottom: '0.5px dashed',
  borderColor: 'grey.400',
  color: 'primary.main',
  textDecoration: 'underline',
  p: 0,
  pl: 1.5,
  '&:last-child': {
    border: 'none',
  },
};

export function SideLinkListBox(props: Props) {
  return (
    <Box sx={{ borderTop: '4px solid', borderColor: 'primary.light' }}>
      <Typography
        variant="h2"
        sx={{
          fontSize: '16px',
          fontWeight: 'bold',
          height: '56px',
          lineHeight: '56px',
          textIndent: '10px',
          border: '0.5px solid',
          borderColor: 'grey.400',
        }}
      >
        {props.listTitle}
      </Typography>
      <List
        sx={{
          border: '0.5px solid',
          borderColor: 'grey.400',
          borderTop: 'none',
          p: 0,
        }}
      >
        {props.links.map((item) => {
          return <LinkListRow link={item} key={item.title} listItemOption={listStyle} />;
        })}
      </List>
    </Box>
  );
}
