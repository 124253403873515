import { Box, Typography } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { BranchItem } from '@/services/data/prefectureLinkList';
import { LinkButton } from '@/components/button/LinkButton';

interface Props {
  branchItem?: BranchItem;
}

export const LifeHelpContentSP: React.VFC<Props> = ({ branchItem }) => {
  const buildHref = (href: string) => {
    if (branchItem) {
      return `${branchItem.href}${href}`;
    }
    return href;
  };
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 0.5,
          bgcolor: 'primary.main',
          p: 0.5,
          fontWeight: 'bold',
        }}
      >
        <ArrowDropDownIcon fontSize="large" sx={{ color: 'primary.contrastText' }} />
        <Typography variant="h3" fontSize="16px" color="white">
          住まいのお役立ち情報
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <LinkButton
          title="部屋探しマニュアル"
          href={buildHref('/sumaisagashi/heyasagashi')}
          buttonStyle={{ borderTop: 0 }}
          pcIcon
        />
        <LinkButton
          title="引越しマニュアル"
          href={buildHref('/sumaisagashi/hikkoshi')}
          buttonStyle={{ borderTop: 0 }}
          pcIcon
        />
        <LinkButton
          title="入居ガイド"
          target="_blank"
          href="https://www.zennichi.net/guide/index.html"
          buttonStyle={{ borderTop: 0 }}
          pcIcon
        />
        <LinkButton
          title="不動産用語集"
          target="_blank"
          href="https://www.re-words.net/"
          buttonStyle={{ borderTop: 0 }}
        />
        <LinkButton
          title="REINS Market Information"
          target="_blank"
          href="http://www.contract.reins.or.jp/"
          buttonStyle={{ borderTop: 0 }}
          pcIcon
        />
        <LinkButton
          title="マンションみらいネット"
          target="_blank"
          href="https://www.mirainet.org/"
          buttonStyle={{ borderTop: 0 }}
          pcIcon
        />
      </Box>
    </>
  );
};
