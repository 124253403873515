import * as React from 'react';
import Image, { StaticImageData } from 'next/image';
import Button from '@mui/material/Button';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import { Chip, SxProps } from '@mui/material';

interface LinkButtonProps {
  title: string;
  href: string;
  image?: StaticImageData;
  variant?: 'h2' | 'body';
  buttonStyle?: SxProps;
  pcIcon?: boolean;
  fontWeightBold?: boolean;
  target?: string;
}

export const LinkButton: React.VFC<LinkButtonProps> = ({
  title,
  href,
  image,
  variant = 'body',
  buttonStyle,
  pcIcon = false,
  fontWeightBold = true,
  target,
}) => {
  return (
    <Button
      variant="outlined"
      size="large"
      fullWidth
      endIcon={<ChevronRightIcon sx={{ color: variant === 'h2' ? '#ffffff' : 'grey.500' }} />}
      href={href}
      target={target}
      sx={{
        justifyContent: 'space-between',
        borderRadius: 0,
        border: variant === 'h2' ? 'none' : '1px solid',
        borderColor: variant === 'h2' ? 'primary.main' : 'grey.400',
        bgcolor: variant === 'h2' ? 'primary.main' : 'white',
        px: '12px',
        ...buttonStyle,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: variant === 'h2' ? 1.5 : 0.75,
          width: '100%',
        }}
      >
        {image && (
          <Box sx={{ position: 'relative', width: '38px', height: '38px', minWidth: '38px' }}>
            <Image src={image} alt="" />
          </Box>
        )}
        <Typography
          variant={variant === 'h2' ? 'h2' : 'body2'}
          sx={{
            color: variant === 'h2' ? 'white' : 'text.primary',
            fontWeight: fontWeightBold ? 'bold' : 'normal',
            fontSize: variant === 'h2' ? '18px' : '13.125px',
          }}
        >
          {title}
        </Typography>
        {pcIcon && (
          <Chip
            label="PC"
            size="small"
            sx={{
              marginLeft: 'auto',
              borderRadius: '4px',
              lineHeight: 1,
              color: 'white',
              fontWeight: 'bold',
              bgcolor: 'grey.500',
              height: '20px',
            }}
          />
        )}
      </Box>
    </Button>
  );
};
